import { Button } from '../ui/button';
import CautionIcon from '../icons/CautionIcon';
import BaseDialog from './BaseDialog';
import useIsLoading from '~/hooks/useIsLoading';
import { cn } from '~/lib/utils';

interface ButtonProps {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  isPrimary?: boolean;
  text: string;
}

interface CautionDialogInterface {
  callToAction: Array<ButtonProps>;
  description: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

export default function CautionDialog({ callToAction, description, isOpen, onClose, title }: CautionDialogInterface) {
  const isLoading = useIsLoading();

  const buttons = callToAction.map((buttonProps) => {
    const isPrimaryButton = buttonProps.isPrimary;

    return (
      <Button
        type="button"
        disabled={isLoading}
        key={buttonProps.text}
        onClick={buttonProps.onClick}
        className={cn(
          `rounded-full border text-sm ${isPrimaryButton ? 'bg-orange-dark px-8 py-3 font-medium text-beige-light' : 'border-transparent bg-transparent px-8 py-2 font-medium text-brown-muted'} ${isPrimaryButton && isLoading ? 'opacity-30' : 'opacity-100'}`,
          buttonProps.className
        )}
      >
        <span>{buttonProps.text}</span>
      </Button>
    );
  });

  return (
    <BaseDialog
      description={description}
      icon={<CautionIcon />}
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      titleClassName="text-orange-dark"
      buttons={buttons}
    />
  );
}
