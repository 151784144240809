import { Notification } from '~/interfaces/Notification';

export enum NotificationType {
  ACCOUNT_DELETED_SUCCESS = 'ACCOUNT_DELETED_SUCCESS',
  AUTH_FAILED = 'AUTH_FAILED',
  COPARENT_UPDATED_SUCCESS = 'COPARENT_UPDATED_SUCCESS',
  COPY_CALENDAR_LINK_SUCCESS = 'COPY_CALENDAR_LINK_SUCCESS',
  EMAIL_UPDATED_SUCCESS = 'EMAIL_UPDATED_SUCCESS',
  FIRST_HOLIDAY_CREATED_SUCCESS = 'FIRST_HOLIDAY_CREATED_SUCCESS',
  GENERIC_ERROR = 'GENERIC_ERROR',
  HOLIDAY_CREATED_ERROR = 'HOLIDAY_CREATED_ERROR',
  HOLIDAY_CREATED_SUCCESS = 'HOLIDAY_CREATED_SUCCESS',
  HOLIDAY_DELETED_ERROR = 'HOLIDAY_DELETED_ERROR',
  HOLIDAY_DELETED_SUCCESS = 'HOLIDAY_DELETED_SUCCESS',
  HOLIDAY_EDITED_ERROR = 'HOLIDAY_EDITED_ERROR',
  HOLIDAY_EDITED_SUCCESS = 'HOLIDAY_EDITED_SUCCESS',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  PARENT_UPDATED_SUCCESS = 'PARENT_UPDATED_SUCCESS',
  OTP_REQUEST_ERROR = 'OTP_REQUEST_ERROR',
  OTP_RESEND_SUCCESS = 'OTP_RESEND_SUCCESS',
  PASSWORD_UPDATED_ERROR = 'PASSWORD_UPDATED_ERROR',
  PASSWORD_UPDATED_SUCCESS = 'PASSWORD_UPDATED_SUCCESS',
  PUBLISH_CALENDAR_SUCCESS = 'PUBLISH_CALENDAR_SUCCESS',
  SIGNUP_SUCCESS = 'SIGNUP_SUCCESS',
  TEMPLATE_REQUIRED = 'TEMPLATE_REQUIRED',
  UNPUBLISH_CALENDAR_SUCCESS = 'UNPUBLISH_CALENDAR_SUCCESS',
  UPDATE_FAILED = 'UPDATE_FAILED',
}

export const notificationData: Record<NotificationType, Notification> = {
  [NotificationType.ACCOUNT_DELETED_SUCCESS]: {
    title: '',
    description: '',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.AUTH_FAILED]: {
    title: 'Error logging in. Please try again.',
    description: '',
    variant: 'destructive',
    duration: 3000,
  },
  [NotificationType.COPARENT_UPDATED_SUCCESS]: {
    title: 'Co-parent name updated.',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.COPY_CALENDAR_LINK_SUCCESS]: {
    title: 'Link copied!',
    description: '',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.EMAIL_UPDATED_SUCCESS]: {
    title: 'Email updated.',
    description: '',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.FIRST_HOLIDAY_CREATED_SUCCESS]: {
    title: '',
    description: '',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.GENERIC_ERROR]: {
    title: 'Something went wrong',
    description: 'Something went wrong. Please try again later.',
    variant: 'destructive',
    duration: 3000,
  },
  [NotificationType.HOLIDAY_CREATED_ERROR]: {
    title: 'Error',
    description: 'There was an error creating your holiday. Please double check your info and try again.',
    variant: 'destructive',
    duration: 3000,
  },
  [NotificationType.HOLIDAY_CREATED_SUCCESS]: {
    title: 'Holiday added.',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.HOLIDAY_DELETED_ERROR]: {
    title: 'Error deleting holiday. Please try again.',
    description: '',
    variant: 'destructive',
    duration: 3000,
  },
  [NotificationType.HOLIDAY_DELETED_SUCCESS]: {
    title: 'Holiday deleted.',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.HOLIDAY_EDITED_ERROR]: {
    title: 'Error editing holiday. Please try again.',
    description: '',
    variant: 'destructive',
    duration: 3000,
  },
  [NotificationType.HOLIDAY_EDITED_SUCCESS]: {
    title: 'Holiday edited.',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.LOGIN_SUCCESS]: {
    title: 'Welcome back!',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.PARENT_UPDATED_SUCCESS]: {
    title: 'Name updated.',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.OTP_REQUEST_ERROR]: {
    title: 'Error sending verification code. Please try again.',
    description: '',
    variant: 'destructive',
    duration: 3000,
  },
  [NotificationType.OTP_RESEND_SUCCESS]: {
    title: 'A new code was sent.',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.PASSWORD_UPDATED_ERROR]: {
    title: 'Your password was succesfully updated.',
    description: '',
    variant: 'destructive',
    duration: 3000,
  },
  [NotificationType.PASSWORD_UPDATED_SUCCESS]: {
    title: 'Password updated.',
    description: '',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.PUBLISH_CALENDAR_SUCCESS]: {
    title: 'Link created!',
    description: '',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.SIGNUP_SUCCESS]: {
    title: '',
    description: '',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.TEMPLATE_REQUIRED]: {
    title: 'Template selection required.',
    description: 'Pick a template to continue.',
    variant: 'destructive',
    duration: 3000,
  },
  [NotificationType.UNPUBLISH_CALENDAR_SUCCESS]: {
    title: 'Sharing link successfully deleted.',
    description: '',
    variant: 'success',
    duration: 3000,
  },
  [NotificationType.UPDATE_FAILED]: {
    title: 'Update failed. Please try again.',
    description: '',
    variant: 'destructive',
    duration: 3000,
  },
};
